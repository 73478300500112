<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="!$permission.isGroup() ? pageNavs : null" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :labels="labels"
                    @handle-toggle="handleStatus"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog :listLength="newsCount" :modulePath="modulePath" :subdomain="subdomain"></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="!$permission.isGroup()">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <router-link class="btn btn-main" :to="{ name: registerRoute }">新規登録</router-link>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal()" v-if="modal">
      <template v-slot:headline>{{ paused ? '公開' : '非公開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            paused
              ? 'このお知らせを公開しますか？'
              : 'このお知らせを非公開にしますか？'
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="cancelChangeStatus">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="paused ? '公開する' : '非公開にする'"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDateAndTime } from '@/helpers/formatData';
import nav from '@/mixins/nav/news';
import modal from '@/mixins/plugin/modal';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'News',
  data: function() {
    return {
      pageName: 'お知らせ',
      modulePath: 'news/getNewsList',
      storeModule: 'news',
      newsId: '',
      paused: true,
    };
  },
  mixins: [nav, modal],
  components: {
    PaginationLog,
    TableWithCheckbox,
    Modal,
  },
  computed: {
    ...mapGetters({
      newsList: 'news/newsList',
      newsCount: 'news/newsCount',
      infor: 'auth/infor',
      newSubdomain: 'common/subdomain',
      isGmoOrOfficeLoginStore: 'common/isGmoOrOfficeLoginStore',
      isGmoOrOfficeLoginStoreAndStore: 'common/isGmoOrOfficeLoginStoreAndStore',
    }),
    subdomain() {
      if (this.$permission.isStoreOrGroup()) {
        return this.infor.event?.subdomain || null;
      } else {
        return (this.isGmoOrOfficeLoginStore ? this.newSubdomain : this.$route.params?.subdomain) || null;
      }
    },
    labels() {
      return [
        { key: 'featuredImage', name: 'サムネイル' },
        { key: 'subject', name: 'タイトル' },
        { key: 'shop', name: '店舗名', notShow: this.$permission.isGmoOrOffice() && !this.isGmoOrOfficeLoginStore },
        { key: 'createDate', name: '公開日時', sortKey: 'createDate' },
        { key: 'validFlag', name: '公開' },
        { isButtonColumn: true }
      ]
    },
    pageNavs() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return this.storePageNavs;
      } else {
        return this.officePageNavs;
      }
    },
    registerRoute() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return 'ShopNewsRegister';
      } else {
        return 'NewsRegister';
      }
    },
    editRoute() {
      if (this.isGmoOrOfficeLoginStoreAndStore) {
        return 'ShopNewsEdit';
      } else {
        return 'NewsEdit';
      }
    },
    dataTable() {
      const editRoute = this.editRoute;
      return this.newsList.map((item) => {
        let buttonLinks = [];
        if (!this.$permission.isGroup()) {
          buttonLinks = [
            { content: '編集', class: 'btn btn-sm btn-bd-main', routeName: editRoute, params: { id: item.id }, },
          ]
        }
        return {
          ...item,
          featuredImage: {
            type: 'image',
            value: item.featuredImage?.url
          },
          shop: item.shop?.name,
          createDate: formatDateAndTime(item.createDate),
          validFlag: {
            isToggleButton: true,
            value: item.validFlag,
            id: item.id,
          },
          buttonLinks: buttonLinks,
        };
      });
    },
  },
  methods: {
    toggleFlag() {
      this.newsList.find((el) => el?.id === this.newsId).validFlag = !this.paused;
    },
    handleStatus(id, valueFlag) {
      this.showModal();
      this.newsId = id;
      this.paused = valueFlag;
      this.newsList.find((el) => el?.id === id).validFlag = valueFlag;
    },
    async changeStatus() {
      const newsId = await this.$store.dispatch('news/updateNewsStatus', {
        subdomain: this.subdomain,
        id: this.newsId,
        status: this.paused,
      })
      this.closeModal();
      if (newsId) this.$message.updated('status');
      else this.toggleFlag();
    },
    cancelChangeStatus() {
      this.handleStatus(this.newsId, !this.paused);
      this.closeModal();
    },
  }
};
</script>
