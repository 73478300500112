export default {
  data: function() {
    return {
      officePageNavs: [
        {
          id: '01',
          name: '一覧',
          path: '/news',
          routeName: 'News',
          includes: false,
        },
        {
          id: '02',
          name: '新規登録',
          path: '/news/register',
          routeName: 'NewsRegister',
          includes: false,
        },
      ],
      storePageNavs: [
        {
          id: '01',
          name: '一覧',
          path: '/news',
          routeName: 'ShopNews',
          includes: false,
        },
        {
          id: '02',
          name: '新規登録',
          path: '/news/register',
          routeName: 'ShopNewsRegister',
          includes: false,
        },
      ],
    };
  },
};
